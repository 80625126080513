<template>
    <div class="user-data">
        <p>{{ age }} anos</p>
        <p>{{ gender }}</p>
    </div>
</template>

<script>
export default{
    props: {
      age: {
        type: String,
      },
      gender: {
        type: String,
      }
    },
}
</script>

<style scoped>
    .user-data{
        width: 100%;
        height: 30%;
        font-size: larger;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-family: 'League Spartan', sans-serif;
        font-weight: 300;
    }

    @media (min-width: 820px){
    .user-data{
      width: 33%;
      height: 100%;
      font-size: xx-large;
      align-items: end;
      justify-content: start
    }
}

</style>