<template>
  <LoadingComponent v-show="isLoading" />
  <GenericContainer :title="'Boas-Vindas!'">
    <div class="container-success">
      <p class="message">{{name}}, estávamos esperando por você!</p>
      <div class="image">
        <img src="../assets/icons/onionsuccessful.svg" alt="" />
        <div class="button">
          <GenericButton text="Continuar" @click="goToLogin" />
        </div>
      </div>
    </div>
  </GenericContainer>
</template>

<script>
import GenericContainer from "./GenericContainer.vue";
import GenericButton from "./GenericButton.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import router from "@/router";
export default {
  components: {
    GenericContainer,
    GenericButton,
    LoadingComponent,
  },
  data() {
    return {
      name: "",
      isLoading: false,
    };
  },

  mounted() {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    this.name = user.name.split(" ")[0];
  },

  methods: {
    goToLogin() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        router.push("/");
      }, 2000);
    },
  },
};  
</script>

<style scoped>
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.container-success {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5em;
  padding-top: 2em;
}

.message {
  text-align: center;
  font-family: "League Spartan", sans-serif;
  font-size: 1.5em;
  padding: 0 1em;
}

.image {
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 1em;
}

.image img {
  height: 100%;
}

.button {
  width: 40%;
  display: flex;
  align-items: end;
  padding-bottom: 1em;
}

@media screen and (min-width: 800px) {
  .message {
    font-size: 1.75em;
  }

  .image {
    height: 25vh;
  }
}
</style>
