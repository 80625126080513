<template>
  <div class="form-select" ref="select">
    <div class="dropdown-trigger" @click="toggleDropdown">
      {{ selectedOption ? selectedOption.label : defaultLabel }}
      <span class="arrow" :class="{ open: isOpen }"></span>
    </div>

    <div v-if="isOpen" class="dropdown-menu">
      <ul>
        <li v-for="option in options" :key="option.value" @click="selectOption(option)"
          :class="{ selected: option.value === selectedOption?.value }">
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.form-select {
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid #d5d5d5;
  border-radius: 18px;
  outline: none;
  background-color: #ffffff;
  font-size: 20px;
  z-index: 200;
}

.dropdown-trigger {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;
  z-index: 2;
}

.dropdown-trigger,
.dropdown-menu {
  position: absolute;
  top: 0;
  color: gray;
}

.dropdown-menu {
  padding-top: 2.5em;
  border-radius: 18px;
  height: 10.5rem;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0 1em;
}

.dropdown-menu li {
  padding: 10px 0;
  cursor: pointer;
  color: #ea7b08;
  border-bottom: 2px solid #277071;
}

.dropdown-menu li:nth-last-child(1) {
  border: none;
}

.arrow {
  border: solid gray;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.2s;
}

.arrow.open {
  transform: rotate(-135deg);
}
</style>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    defaultLabel: {
      type: String,
      default: "",
    },
    validator: {
      type: Function,
      default: () => true,
    },
    required: {
      typeof: Boolean,
      default: false,
    },
  },
  emits: ["update:selected", "validityChange"],
  data() {
    return {
      isOpen: false,
      selectedOption: null,
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside)
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.isOpen = false;
      this.$emit("update:selected", option.value);
      this.$emit("validityChange", this.validator(option.value));
    },
    handleClickOutside(event) {
        if(this.$refs.select && !this.$refs.select.contains(event.target)) {
            this.isOpen = false
        }
    }
  },
};
</script>
