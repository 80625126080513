<template>
  <div class="input-container">
    <input :class="{ invalid: (!isValid && touched) || inputHasBackendError || (backendError && backendError != '') }"
      :required="required" :type="currentType" :maxlength="maxLength" :id="id" v-model="internalValue"
      :placeholder="placeholder" :style="`padding-right: ${paddingRight}em`" @blur="handleBlur" />
    <a v-if="type === 'password'" @click="togglePassword" class="eye-password">
      <img :src="isPasswordVisible ? eyeSlashSolid : eyeRegular" alt="Toggle password visibility" />
    </a>
    <p v-if="!backendError" :class="['error-msg', { 'error-msg-visible': !isValid }]" :style="{ color: textColor }">{{
      errorMessage }}</p>
    <p v-else :class="['error-msg', { 'error-msg-visible': backendError && backendError != '' }]"
      :style="{ color: textColor }">
      {{ backendError }}
    </p>
  </div>
</template>

<style scoped>
* {
  font-family: 'League Spartan', sans-serif;
}

.input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.input-container input {
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid #d5d5d5;
  border-radius: 18px;
  padding-inline: 1rem;
  outline: none;
  font-size: 20px;
  color: grey;
}

@supports (-webkit-touch-callout: none) {
  .input-container {
    position: relative;
  }

  .input-container input[type="date"] {
    min-width: 86%;
    background-color: white;
  }

  .input-container input[type="date"]::before {
    content: attr(placeholder);
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: grey;
    pointer-events: none;
  }

  .input-container input[type="date"]:valid::before,
  .input-container input[type="date"]:focus::before {
    content: '';
  }

}

.input-container .invalid {
  border: solid #ea7b08;
}

.eye-password {
  position: absolute;
  right: 13px;
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.eye-password img {
  width: 100%;
  height: 100%;
}

.input-container p {
  color: #ea7b08;
  text-align: start;
  font-size: 16px;
  padding-inline: 10px;
  padding-top: 4px;
  width: 100%;
  height: 2.6rem;
  visibility: hidden;
}

.input-container p.error-msg-visible {
  visibility: visible;
}

.input-container input:focus {
  border: 1.5px solid #EA7B08;
  outline: none;
}

.input-container input::placeholder {
  color: grey;
  opacity: 1;
}

.input-container input[type="date"] {
  color: grey;
  padding-right: 1rem;
}

@media screen and (max-width: 800px) {
  .input-container p {
    font-size: 1.1rem;
    width: 100%;
  }
}
</style>

<script>
import eyeRegular from "../assets/icons/eye-regular.svg";
import eyeSlashSolid from "../assets/icons/eye-slash-solid.svg";

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    paddingRight: {
      type: Number,
      default: 0,
    },
    required: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    validator: {
      type: Function,
      default: () => true,
    },
    errorMessage: {
      type: String,
    },
    hasBackendError: {
      type: Boolean,
      default: false,
    },
    backendErrorMessage: {
      type: String,
    },
    modelValue: {
      type: String,
    },
    maxLength: {
      type: String,
      default: '500',
    },
    textColor: {
      type: String,
      default: "#ea7b08",
    },
  },

  emits: ["update:vModel", "validityChange"],

  data() {
    return {
      isValid: true,
      touched: false,
      internalValue: this.modelValue,
      backendError: this.backendErrorMessage,
      inputHasBackendError: this.hasBackendError,
      isPasswordVisible: false,
    };
  },

  computed: {
    currentType() {
      return this.isPasswordVisible && this.type === "password" ? "text" : this.type;
    },
    eyeRegular() {
      return eyeRegular;
    },
    eyeSlashSolid() {
      return eyeSlashSolid;
    }
  },

  watch: {
    modelValue(newVal) {
      this.internalValue = newVal;
    },
    backendErrorMessage(newError) {
      this.backendError = newError;
    },
    hasBackendError(newVal) {
      this.inputHasBackendError = newVal
    }
  },
  methods: {
    handleBlur() {
      this.touched = true;
      this.validateInput();
    },

    togglePassword() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    
    validateInput() {
      this.isValid = this.validator(this.internalValue);
      this.backendError = "";
      this.$emit("update:vModel", this.internalValue);
      this.$emit("validityChange", this.isValid);
    },
  },
};
</script>