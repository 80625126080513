<template>
    <GenericContainer showIcon="false" title="Estamos preparando seu perfil!">
        <div class="container-final">
            <p class="message-final">Seus dados estão sendo processados e em breve você será notificado sobre o
                resultado! Obrigado por ter participado!</p>
            <div class="image">
                <img src="../assets/icons/onion-final.svg" alt="">
            </div>
            <!-- <div class="butoon">
                <GenericButton text="Voltar" @click="redirectToProfile" />
            </div> -->
        </div>
    </GenericContainer>
</template>

<script>
import router from '@/router';
import GenericContainer from './GenericContainer.vue';
// import GenericButton from './GenericButton.vue';

  export default {
    components: {
        GenericContainer,
        // GenericButton,
    },
    methods: {
        redirectToProfile() {
            const idUser = localStorage.getItem("idUser")
            router.push(`/profile/${idUser}`)
        }
    }
  };
</script>

<style scoped>
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.container-final {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    gap: 3em;
}

.message-final {
    font-family: "League Spartan", sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
}

.image img {
    height: 25vh;
}

.butoon {
    width: 100%;
}

@media screen and (min-width:420px) {
    .image img {
        height: auto;
    }
}

@media screen and (min-width:800px) {
    .butoon {
        width: 50%;
    }
}
</style>