<template>
  <div id="card" 
     @click="navigateToQuiz"
     :style="{
       cursor: blocked ? 'not-allowed' : 'pointer',
       background: accessedAt !== null && accessedAt !== '' ? '#ea7b08' : ''
     }">

    <div class="icon-holder">
      <img
        :class="{
          'open-icon': accessedAt === null && !blocked,
          'orange-icon': accessedAt !== null,
          'product-icon': true
        }"
        :src="accessedAt !== null ? orangeIcon : (blocked ? closedLock : openLock)"
        alt="status-icon"
      />

    </div>
    <div v-if="accessedAt !== null" class="name-holder">
      {{ nome }}
    </div>
  </div>
</template>

<script>
    import openLock from '@/assets/icons/openLkr.svg';
    import closedLock from '@/assets/icons/closedlkr.svg';
    import orangeIcon from '@/assets/icons/orangeIcon.svg'

    export default {
      props: {
        nome: {
          type: String,
          default: ""
        },
        blocked: {
          type: Boolean,
          required: true
        },
        finished: {
          type: Boolean,
          default: false,
        },
        accessedAt: {
          type: String,
          default: '',
        },
        idUser: {
          type: String,
          required: true
        },
        idQuiz: {
          type: String,
          required: true
        }
      },
      data() {
        return {
          openLock,
          closedLock,
          orangeIcon
        };
      },
      methods: {
        navigateToQuiz() {
          if (!this.blocked && !this.finished) {
            this.$router.push(`/start-quiz/${this.idUser}/${this.idQuiz}`);
          } else if (!this.blocked && this.finished ) {
            this.$router.push(`/final`);
          }
        }
      }
    };

</script>

<style scoped>
#card {
  position: relative;
  border-radius: 30px 30px 30px 30px;
  background-color: rgba(234, 125, 8, 0.329);
  height: 200px;
  margin-bottom: 40px;
  width: 100%;
}

.gradient-background {
  background-color: purple;
}

.icon-holder {
  position: absolute;
  top: -27px;
  left: 50%;
  transform: translate(-50%);
  height: 55px;
  width: 55px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: white;
  font-weight: 400;
  margin-top: 50px;
  font-family: 'League Spartan', sans-serif;
}

.product-icon {
  height: 80px;
  width: 80px;
}

.orange-icon {
  width: 50px;
  height: 50px;
}

.open-icon{
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

@media (min-width: 820px){
 
}

/* @media (max-width: 1500px) {
  .icon-holder {
    height: 3.7em;
    width: 3.7em;
    top: -30px;
  }
}

@media (max-width: 992px) {
  .icon-holder {
    height: 3.5em;
    width: 3.5em;
    top: -25px;
  }
}

@media (max-width: 768px) {
  .icon-holder {
    height: 3em;
    width: 3em;
    top: -20px;
  }
}

@media (max-width: 576px) {
  .icon-holder {
    height: 2.5em;
    width: 2.5em;
    top: -15px;
  }
}

.product-icon {
  height: 6em;
  width: 4.3em;
}

@media (max-width: 1200px) {
  .product-icon {
    height: 5.5em;
    width: 3.9em;
  }
}

@media (max-width: 992px) {
  .product-icon {
    height: 5em;
    width: 3.5em;
  }
}

@media (max-width: 768px) {
  .product-icon {
    height: 4em;
    width: 2.9em;
  }
}

@media (max-width: 576px) {
  .product-icon {
    height: 3em;
    width: 2.2em;
  }
}

.icon-holder:hover {
  scale: 1.04;
  transition: all ease 0.3s;
}

@media (max-width: 650px) {
  #card {
    border-radius: 35px;
    height: 20em;
    width: 90%;
    margin-top: 3em;
    border: 1px solid rgba(234, 125, 8, 0.664);
  }
}

.name-holder {
  font-family: League Spartan;
  font-size: 200%;
  font-weight: 700;
  line-height: 58.88px;
  color: white;
  text-align: right;

} */
</style>
