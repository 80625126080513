<template>
  <div class="photo-container">
    <div 
      class="user-photo" 
      :style="{ backgroundImage: `url(${backgroundImage})` }">
    </div>
  </div>
</template>

<script>
import DefaultImage from "../../assets/Frame1.svg"
export default {
  props: {
    Image: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      defaultImage: DefaultImage, 
      backgroundImage: null
    };
  },
  mounted() {
    this.backgroundImage = this.Image ? this.Image : this.defaultImage;
  },
  watch: {
    Image(newImage) {
      this.backgroundImage = newImage ? newImage : this.defaultImage;
    }
  }
}
</script>

<style scoped>

.photo-container{
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.user-photo {
  position: absolute;
  top: -100px;
  border: 6.5px solid #184E4F;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

@media (min-width:820px){
  .user-photo{
    height: 350px;
    width: 350px;
  }
}
</style>
