<template>
  <LoadingComponent v-show="isLoading" class="loading" />
  <div class="motivators-container">
    <GenericContainer title="O que realmente te motiva?">
      <div class="motivators-content">
        <div class="text-container">
          <p>
            Iniciar uma carreira é uma jornada de descobertas e crescimento. Todos nós temos motivações diferentes, e o objetivo deste self-assessment é ajudar você a identificar o que realmente te inspira e te faz crescer — aquilo que vai guiar você para se tornar a melhor versão de si mesmo.
          </p>
          <ul>
            <li><span class="emphasis">O foco é em você</span>: suas paixões, interesses e o profissional que quer se tornar.</li>
            <li>Responda com <span class="emphasis">sinceridade total</span>, sem pensar no que os outros esperam de você.</li>
            <li>O questionário é confidencial: só você verá os resultados. (quando estiverem prontos 😅 )</li>
            <li>Seja honesto consigo mesmo para identificar o que realmente te motiva e construir uma carreira com significado.</li>
          </ul>
          <p>Pronto para começar? Vamos descobrir o que te move de verdade!</p>
        </div>
        <GenericButton text="Iniciar" @click="startQuiz" />
      </div>
    </GenericContainer>
  </div>
</template>

<script>
  import router from '@/router';
  import axios from 'axios';
  import LoadingComponent from '@/components/LoadingComponent.vue';
  import GenericContainer from '@/components/GenericContainer.vue';
  import GenericButton from '@/components/GenericButton.vue';

  export default {
    name: 'MotivatorsView',
    components: {
      LoadingComponent,
      GenericContainer,
      GenericButton,
    },
    data() {
      return {
        image: require("../assets/cebola-quiz.png"),
        isLoading: false,
      }
    },
    methods: {
      async startQuiz() {
        const token = localStorage.getItem('userJWT');
        const idUser = this.$route.params.idUser;
        const idQuiz = this.$route.params.idQuiz;

        this.isLoading = true

        try {
          const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/start-quiz/${idUser}/${idQuiz}`, 
            {}, 
            {headers: {'Authorization': token}}
          );

          router.push(`/quiz/${response.data.idUser}/${response.data.idQuiz}`)
        } catch (error) {
          const errorData = error.response.data

          if(errorData.status  === 403) {
            router.push(`/start-quiz/${errorData.expectedIdUser}/${idQuiz}`)
          }

          if (errorData.status === 401) {
            router.push({path: "/", query: {"expired": true}});
          }

          if(errorData.status === 303) {
            router.push(`/final`)
          }
        } finally {
          this.isLoading = false
        }
      }
    }
  };
</script>

<style scoped>
  /* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .motivators-container {
    min-height: 100vh;
    height: auto;
    width: 100vw;
    background-color: #FB8409;
    background-image: url('../assets/half-white-blue-lines-bg.svg');
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em 0;
    background-attachment: fixed;
  }

  .motivators-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5em;
    padding: 0 1em;
    padding-bottom: 2em;
  }
  
  .text-container {
    height: 50vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 1em;
    gap: 1em;
    font-family: "League Spartan", sans-serif;
    font-weight: 300;
    font-size: 1.25rem;
  }
  
  .text-container ul{
    display: flex;
    flex-direction: column;
    gap: .5em;
    list-style-type: disc;
    list-style-position: inside;
  }

  .emphasis {
    font-weight: 600;
  }

  @media screen and (min-width: 800px) {
    .text-container p {
    font-size: 1.5rem;
  }
  }
</style>