<template>
  <GenericContainer title="Se quiser, adicione uma foto para personalizar seu perfil." :show-icon="false">
    <div v-if="!selectedImage" class="photo-input-content">
      <div ref="cardPhoto" class="foto">
        <label for="picInput">
          <img id="onion-sign-icon" src="../assets/icons/add-photo.svg" alt="imagem-cebola-placa">
        </label>
        <input type="file" name="profilePic" id="picInput" accept="image/*" style="display: none;"
          @change="handleFileSelection">
      </div>

      <div class="error-message">
        <p>{{ errorMessage }}</p>
      </div>

      <div class="buttons no-image">
        <div class="button">
          <GenericButton text="Pular" @click="updatePhoto(null)" />
        </div>
      </div>
    </div>
    <div v-else class="photo-input-content">
      <div ref="previewPhoto" class="photoPreview foto"></div>

      <div class="error-message">
        <p>{{ errorMessage }}</p>
      </div>

      <div class="buttons">
        <div class="button">
          <GenericButton text="Alterar" @click="removeSelectedImage" />
        </div>
        <div class="button">
          <GenericButton text="Enviar" @click="updatePhoto(selectedImage)" />
        </div>
      </div>
    </div>
  </GenericContainer>
</template>

<script>
import router from '@/router';
import GenericContainer from './GenericContainer.vue';
import GenericButton from './GenericButton.vue';
import axios from 'axios';

export default {
  components: {
    GenericContainer,
    GenericButton,
  },
  emits: ['file-selected'],
  data() {
    return {
      showRemoveButton: false, 
      errorMessage: "", 
      selectedImage: null,
      idUser: this.$route.params.idUser
    };
  },
  methods: {
    async handleFileSelection(event) {
      const file = event.target.files[0];
      
      this.errorMessage = ""; 

      if (file) {
        try {
          const result = await this.validateImage(file);
          if (!result.isValid) {
            this.errorMessage = result.error || "Imagem inválida";
          } else {
            this.displayPreviewImage(file);
            this.errorMessage = ""; 
            this.selectedImage = file;
          }
        } catch (error) {
          this.errorMessage = "Erro ao validar a imagem."; 
        }
      }
    },

    displayPreviewImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.previewPhoto.style.backgroundImage = `url(${e.target.result})`;
      };
      reader.readAsDataURL(file);
    },

    validateImage(file) {
      const imageConfig = {
        allowedTypes: ['image/png'],
        maxSize: 5 * 1024 * 1024,
        minWidth: 100,
        minHeight: 100,
      };

      if (!file) {
        return { isValid: false, error: "Nenhuma imagem foi selecionada!" };
      }

      if (!imageConfig.allowedTypes.includes(file.type)) {
        return { isValid: false, error: "Tipo de arquivo não permitido." };
      }

      if (file.size > imageConfig.maxSize) {
        return { isValid: false, error: "O arquivo excede o tamanho máximo permitido." };
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            if (img.width < imageConfig.minWidth) {
              resolve({ isValid: false, error: `A largura mínima é ${imageConfig.minWidth}px.` });
              return;
            }

            if (img.height < imageConfig.minHeight) {
              resolve({ isValid: false, error: `A altura mínima é ${imageConfig.minHeight}px.` });
              return;
            }

            resolve({ isValid: true });
          };

          img.onerror = () => reject(new Error("Erro ao carregar a imagem."));
          img.src = event.target.result;
        };

        reader.onerror = () => reject(new Error("Erro ao ler o arquivo."));
        reader.readAsDataURL(file);
      });
    },

    removeSelectedImage() {
      this.selectedImage = null;
      this.$refs.previewPhoto.style.backgroundImage = "";
      this.errorMessage = "";
    },
    
    async updatePhoto(photo) {
      const fileData = new FormData();
      fileData.append("idUser", this.idUser);
      fileData.append("photo", photo);
      
      const token = localStorage.getItem("userJWT");
      
      try {
        await axios.put(`${process.env.VUE_APP_BASE_URL}/user/${this.idUser}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        })

        router.push(`/profile/${this.idUser}`)
      }catch(error) {
        if(error.response?.data) {
          const data  = error.response.data
          this.file = null
          if (data.status === 400) {
            this.errorMessage = error.response.data.message
          }
          else if (data.status === 401) {
            router.push({path: "/", query: {"expired": true}});
          }
          else if (data.status === 403) {
            router.push(`/photo/${data.expectedIdUser}`)
          }
          else if (data.status === 500) {
            this.errorMessage = "Algo deu errado. Tente novamente!"
          }
          else {
            router.push("/error/system-failure")
          }
        } 
        else {
          router.push("/error/system-failure")
        }
      }
    }
  },
};
</script>

<style scoped>
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.photo-input-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 1.5em;
  padding: 5vh 0;
}

.buttons {
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.button {
  width: 40%;
}

.buttons.no-image {
  justify-content: right;
}

#onion-sign-icon {
  cursor: pointer;
}

#onion-sign-icon,
.photoPreview {
  border-radius: 50%;
  flex-shrink: 0;
  width: 55vw;
  height: 55vw;
}

.photoPreview {
  background-size: cover;
  background-position: center;
  border: 8px solid #298487;
}

.error-message {
  color: #EA7B08;
  font-size: 1.25rem;
  height: 2em;
  font-family: "League Spartan", sans-serif;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-height: 375px) {
  .photo-input-content {
    padding-top: 1em;
  }

  #onion-sign-icon,
  .photoPreview {
    width: 40vh;
    height: 40vh;
  }
}



@media screen and (min-width: 810px) {
  .photo-input-content {
    padding-top: 10em;
  }

  #onion-sign-icon,
  .photoPreview {
    width: 45vw;
    height: 45vw;
  }

  .error-message {
    font-size: 1.75em;
  }
}

@media screen and (min-width: 810px) and (max-height: 1080px) {
  .photo-input-content {
    gap: .5em;
  }

  .photo-input-content {
    padding-top: 0;
  }

  #onion-sign-icon,
  .photoPreview {
    width: 35vh;
    height: 35vh;
  }

  .error-message {
    font-size: 1em;
  }
}

@media screen and (min-width: 960px) {
  .photo-input-content {
    padding-top: 4em;
    gap: 4em;
  }

  #onion-sign-icon,
  .photoPreview {
    width: 35vh;
    height: 35vh;
  }
}

@media screen and (min-width: 1080px) {
  .error-message {
    font-size: 1.5em;
  }
}
</style>