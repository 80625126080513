<template>
    <LoadingComponent v-show="isLoading" class="loading" />
    <div class="container">
        <GenericContainer title="Confirme sua conta">
            <div class="user-verification-container">
                <div class="container-header">
                    <img src="../assets/icons/onion-email-icon.svg" alt="">
                    <p>
                        Insira aqui o código de quatro dígitos enviado para o seu e-mail.
                    </p>
                </div>
                <div class="inputs-container">
                    <div class="inputs">
                        <input v-for="(code, index) in codes" :key="index" type="text"
                            :class="{ 'code-input': true, 'input-error': errorMessage != '' }" v-model="codes[index]"
                            @input="clearBackendError()" @keyup="toUpper(index)" />
                    </div>
                    <p class="error-message">{{ errorMessage }}</p>
                </div>
                <span class="new-code-span">
                    Não encontrou o e-mail? Verifique sua caixa de spam ou <a @click.prevent="sendAuthenticationEmail">
                        solicite novo código de confirmação</a>.
                </span>
                <div class="button-container">
                    <GenericButton :isValid="!isDisabled" v-on:click="editUserCode" />
                </div>
            </div>
        </GenericContainer>
    </div>
</template>

<script>
import router from '@/router';
import axios from 'axios';
import LoadingComponent from '@/components/LoadingComponent.vue';
import GenericContainer from '@/components/GenericContainer.vue';
import GenericButton from '@/components/GenericButton.vue';

export default {
    components: {
        LoadingComponent,
        GenericContainer,
        GenericButton,
    },
    data() {
        return {
            codes: ['', '', '', ''],
            response: {
                idUser: '',
                code: ''
            },
            errorMessage: '',
            isLoading: false,
        }
    },

    computed: {
        isDisabled() {
            return this.codes.some(code => code === '') || this.errorMessage != '';
        },

        codeString() {
            if (this.codes.every(code => code !== '')) {
                return this.codes.join('');
            } else {
                return null;
            }
        }
    },
    
    mounted() {
        this.response.idUser = localStorage.getItem('idUser');

        var elts = document.getElementsByClassName('code-input')
        Array.from(elts).forEach(function(elt){
            elt.addEventListener("input", function() {
                if (elt.value.length > 1) {
                    elt.value = elt.value.slice(0, 1);
                }
            });

            elt.addEventListener("keyup", function(event) {
                if ((event.keyCode === 13 || elt.value.length == 1) && elt.nextElementSibling != null) {
                    elt.nextElementSibling.focus();
                    elt.nextElementSibling.select();
                }
            });

            elt.addEventListener("keydown", function(event) {
                if (event.key === "Backspace" &&  elt.value === '' && elt.previousElementSibling != null) {
                    elt.previousElementSibling.focus(); 
                    elt.previousElementSibling.select();
                }   
            })
        });
    },

    methods: {
        async sendAuthenticationEmail() {
            this.isLoading = true

            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/auth`, {
                    idUser: this.response.idUser,
                });
            } catch (error) {
                router.push("/error/system-failure");
            } finally {
                this.isLoading = false
            }
        },
        async editUserCode() {
            this.response.code = this.codeString;
            this.isLoading = true

            try {
                const result = await axios.put(`${process.env.VUE_APP_BASE_URL}/auth`, {
                    idUser: this.response.idUser,
                    code: this.response.code
                });
                
                this.errorMessage = '';

                const responseBody = {
                    idUser: result.data.idUser,
                    name: result.data.name,
                    token: result.data.token
                };

                localStorage.setItem('userInfo', JSON.stringify(responseBody));
                
                router.push("/register/successfulverification");
            } catch (error) {
                if (error.response) {
                    if (error.response.data.status === 400) {
                        this.changeErrorMessage(error.response.data.message);
                    } else {
                        router.push("/error/system-failure");
                    }
                }
            } finally {
                this.isLoading = false;
            }
        },
        changeErrorMessage(message) {
            if (message === "Token de autenticação expirado.") {
                this.errorMessage = "Limite de tentativas atingido. Foi enviado um novo código para seu e-mail."
            } else {
                this.errorMessage = message
            }
        },
        clearBackendError() {
            if (this.errorMessage != "") {
                this.errorMessage = '';
            }
        },
        toUpper(index) {
            this.codes[index] = this.codes[index].toUpperCase(); 
        },
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.container {
    width: 100vw;
    min-height: 100vh;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em 0;
    background-color: #EA7B08;
    background-image: url('../assets/half-white-blue-lines-bg.svg');
    background-size: cover;
    background-attachment: fixed;
}

.user-verification-container {
    height: 100%;
    width: 100%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}

.container-header {
    display: flex;
    height: 12vh;
    gap: 1em;
    align-items: center;
}

.container-header img {
    height: 100%;
}

.container-header p {
    font-family: "League Spartan", sans-serif;
    font-size: 1.25rem;
}

.inputs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.inputs {
    width: 100%;
    display: flex;
    padding: 0 1em;
    justify-content: space-between;
}

.inputs input {
    width: 21%;
    aspect-ratio: 1 / 1;
    border-radius: 10px;
    border: 3px solid #277071;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2em;
    font-weight: 600;
}

.inputs input:focus {
    outline: none;
}

.inputs input::-webkit-outer-spin-button,
.inputs input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.inputs input[type=number] {
    appearance: textfield;
}

.inputs .input-error {
    border: 3px solid #EA7B08;
}

.error-message {
    color: #ea7b09;
    font-size: 1.15rem;
    font-family: "League Spartan", sans-serif;
    text-align: center;
    min-height: 1.15em;
    width: 100%;
}

.new-code-span {
    padding: 0 1em;
    text-align: center;
    font-size: 1.15rem;
    font-family: "League Spartan", sans-serif;
    width: 100%;
}

.new-code-span a {
    color: #ea7b09;
    cursor: pointer;
    text-align: center;
}

.new-code-span a:hover {
    text-decoration: underline;
}

.button-container {
    margin-bottom: 1em;
    width: 10em;
}

@media screen and (min-width: 500px) {
    .inputs-container {
        width: 80%;
    }

    .container-header {
        height: 20vh;
    }
}

@media screen and (min-width: 800px) {
    .user-verification-container {
        padding: 1em 4em;
    }

    .inputs-container {
        width: 90%;
    }

    .inputs input {
        font-size: 2.5rem;
    }

    .container-header {
        height: 13vh;
    }

    .container-header p {
        font-size: 1.5em;
    }

    .new-code-span {
        font-size: 1.5em;
        padding: 1em 0;
    }
}

@media screen and (min-width: 750px) and (max-height: 500px) {
    .container-header {
        height: 30vh;
    }
}

@media screen and (min-width: 1080px) {
    .inputs-container {
        width: 80%;
    }

    .container-header {
        height: 15vh;
    }
}

@media screen and (min-width: 1240px) {
    .inputs-container {
        width: 90%;
    }
}
</style>