<template>
  <div class="generic-container">
    <div class="container-header">
      <h1 class="title">{{ title }}</h1>
    </div>
    <div class="container-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ""
      },
      width: {
        type: String,
        default: "100%"
      },
      height: {
        type: String,
        default: "100%"
      }
    }
  };
  </script>

<style scoped>
.generic-container {
  gap: 1em;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  width: 90%;
  height: 90%;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
  box-shadow: 10px 10px 4px #00000040;
}

.container-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .75em;
  width: 100%;
  padding: 0 2em;
}

.container-header img {
  width: 4.5em;
}

.title {
  display: flex;
  justify-content: center;
  width: 80%;
  font-family: "League Spartan", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  color: #FB8409;
  text-align: center;
}

.container-content {
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 800px) {
  .generic-container {
    height: 80%;
    width: 60%;
  }

  .title {
    font-size: 2.25rem;
  }
}

@media screen and (min-width: 800px) and (max-height: 500px) {
  .generic-container {
    padding-top: 1em;
  }

  .title {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1240px) {
  .generic-container {
    height: 80%;
    width: 35%;
  }
}
</style>