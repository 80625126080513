<template>
    <GenericContainer :show-icon="false" title="Ansioso para participar do Self?">
        <div class="wait-list-content">
            <div class="info">
                <h2 class="sub-title">{{ subTitle }}</h2>
                <div v-if="!registered" class="wait-list-form">
                    <FormInput type="email" id="email" placeholder="E-mail"
                        error-message="E-mail inválido, tente novamente." :validator="validateEmail"
                        v-model:v-model="email" :backend-error-message="backendErrorMessage"
                        @update:v-model="() => backendErrorMessage = ''" />
                    <div class="button">
                        <GenericButton :is-valid="isValid" @click="sendEmail" />
                    </div>
                </div>
                <p v-else class="sucess-message">
                    Esperamos te ver em breve!
                </p>
            </div>
            <img class="image" src="../assets/icons/waitlist-image.svg" alt="cebola">
        </div>
    </GenericContainer>
</template>

<script>
    import GenericContainer from './GenericContainer.vue';
    import FormInput from './FormInput.vue';
    import GenericButton from './GenericButton.vue';
    import axios from "axios";
    export default{
        components: {
            GenericContainer,
            FormInput,
            GenericButton,
        },
        data() {
            return {
                email: "",
                subTitle: "Entre para nossa wait list, cadastre seu e-mail!",
                backendErrorMessage: "",
                registered: false,
            }
        },
        methods: {
            validateEmail(email) {
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                return regex.test(email)
            },
             async sendEmail() {
                try {
                const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/wait-list`, {
                    email: this.email
                });
                if (response.status === 201) {
                        // Atualiza o subtítulo e o estado de registro com base na resposta de sucesso
                        this.subTitle = "Sua solicitação para entrar na waitlist foi feita.";
                        this.registered = true;
                    }
            } catch (error) {
                console.log(error);
                if(error.response?.data?.status == 409) {
                    this.backendErrorMessage = "Email já registrado."
                }
                else if(error.response?.data?.status == 400) {
                    this.backendErrorMessage = "E-mail inválido, tente novamente."
                }
                else {
                    this.backendErrorMessage = "Algo deu errado, tente novamente."
                }
            }
        }
        },
        computed: {
            isValid() {
                return this.validateEmail(this.email) && this.backendErrorMessage == "";
            },
        }
    }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

/* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
    */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.wait-list-content {
    height: 100%;
    padding: 0 2em;
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3em;
}

.info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3em;
}

.wait-list-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 15vh;
}

.sucess-message,
.sub-title {
    text-align: center;
    font-family: "League Spartan", sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
}

.sucess-message {
    height: 15vh;
    display: flex;
    align-items: start;
    justify-content: center;
}

.button {
    width: 70%;
    padding-top: 2em;
    max-width: 15em;
    display: flex;
    justify-content: center;
}

.image {
    width: 60%;
    bottom: 0;
}

@media screen and (min-width: 660px) and (max-height: 450px) {

    .sucess-message,
    .wait-list-form {
        height: 30vh;
    }
}

@media screen and (min-width: 1240px) {
    .image {
        width: 50%;
    }

    .sucess-message,
    .sub-title {
        font-size: 1.75rem;
    }
}
</style>