<template>
    <div class="body">
        <LoadingComponent v-show="isLoading" class="loading" />

        <div class="container">
            <div class="header">
                <div class="icon"><img src="../assets/icons/cebola-azul.png"></div>
                <div class="title"><span>Login</span></div>
            </div>

            <div class="main">
                <div class="error-container">
                    <p class="backend-error-msg" v-show="hasBackendError">{{ backendErrorMsg }}</p>
                </div>
                <div class="inputs">
                    <FormInput :required="true" type="text" id="email-input" placeholder="E-mail"
                        :validator="(email) => validateInputValue(email, /^[^\s@]+@[^\s@]+\.[^\s@]+$/)"
                        :has-backend-error="hasBackendError || (showValidation && !validFields.email)"
                        error-message="Formato de e-mail inválido." @update:v-model="updateEmail"
                        :text-color="'#E5A159'" @validity-change="(value) => (validFields.email = value)" />

                    <FormInput :required="true" type="password" :padding-right="2.2" id="password-input"
                        placeholder="Senha" :has-backend-error="hasBackendError"
                        error-message="A senha deve ter no mínimo 8 caracteres, incluindo letras maiúsculas, minúsculas, números e caracteres especiais."
                        @update:v-model="updatePassword" :text-color="'#E5A159'"
                        @validity-change="(value) => (validFields.password = value)" />
                </div>
            </div>

            <div class="footer">
                <div class="actions">
                    <GenericButton :isValid="isFormValid()" @click="handleSubmit" />
                    <div class="sign-up"><button @click="navigateToRegister">Cadastrar-se</button></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GenericButton from '@/components/GenericButton.vue';
import FormInput from '@/components/FormInput.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import { nextTick } from 'vue';
import axios from 'axios';
import router from '@/router';

export default {
    data() {
        return {
            email: '',
            password: '',
            attempts: 0,
            validFields: {
                email: true,
                password: true
            },
            backendErrorMsg: 'Credenciais inválidas, tente novamente.',
            hasBackendError: false,
            isLoading: false,
            showValidation: false,
        }
    },
    
    components: {
        GenericButton,
        FormInput,
        LoadingComponent
    },

    methods: {
        validateInputValue(value, regex) {
            return regex.test(value)
        },

        updateEmail(value) {
            this.email = value;

            if (this.hasBackendError) {
                this.hasBackendError = false;
            }
        },

        updatePassword(value) {
            this.password = value;

            if (this.hasBackendError) {
                this.hasBackendError = false;
            }
        },

        async handleSubmit() {
            this.showValidation = true;
            this.isLoading = true;

            this.validFields.email = this.validateInputValue(this.email, /^[^\s@]+@[^\s@]+\.[^\s@]+$/);
            this.validFields.password = this.validateInputValue(this.password, /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,}$/);

            this.attempts += 1;

            if (this.attempts <= 5) {
                const formData = {
                    email: this.email,
                    password: this.password,
                };
                const idQuiz = "670def0ffbfc57282bfe6912"
                
                try {
                    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/login`, formData);

                    setTimeout(() => {
                        if (response.data.status == 200) {
                            router.push(`/start-quiz/${response.data.idUser}/${idQuiz}`);
                            localStorage.setItem("userJWT", response.data.token);
                        }

                        this.isLoading = false;
                    }, 2000);
                } catch (error) {
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 2000);

                    if(error.response.data.status == 303) {
                        router.push(`/start-quiz/${error.response.data.idUser}/${idQuiz}`);
                        localStorage.setItem("userJWT", error.response.data.token);
                    } else if (error.response.data.message == "This user is not active.") {
                        this.sendAuthenticationEmail(error.response.data.idUser)
                    } else if (error.response?.data.status == 400 || error.response?.data.status == 404) {
                        this.backendErrorMsg = "";
                        this.hasBackendError = false;

                        await nextTick();

                        this.backendErrorMsg = "Credenciais inválidas, tente novamente.";
                        this.hasBackendError = true;
                    } else {
                        router.push("/error/system-failure");
                    }
                }
            } else {
                setTimeout(() => {
                    this.isLoading = false;
                }, 2000);
            }
        },

        isFormValid() {
            return (
                Object.values(this.validFields).every((isValid) => isValid) &&
                this.email != "" &&
                this.password != "" &&
                !this.hasBackendError
            );
        },

        navigateToRegister() {
            router.push('/register');
        },

        async sendAuthenticationEmail(idUser) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/auth`, {
                    idUser: idUser,
                });
                router.push('/user/verification');

            } catch (error) {
                router.push("/error/system-failure");
            } finally {
                this.isLoading = false;
            }
        }
    },

    mounted() {
        const isSessionExpired = this.$route.query.expired;
        if (isSessionExpired) {
            this.backendErrorMsg = "Sua sessão expirou.";
            this.hasBackendError = true;
        }
    },
}
</script>

<style scoped>
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

*,
.body {
    font-family: 'League Spartan', sans-serif;
}

.body {
    background-color: #ea7b08;
    background-image: url('../assets/half-white-blue-lines-bg.svg');
    background-size: cover;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    background-color: #f9f9f9;
    width: 35rem;
    height: 45rem;
    border-radius: 30px;
    padding-inline: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 10px 10px 4px #00000040;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 65px;
    height: 70px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.icon img {
    width: 100%;
    height: 100%;
}

.title {
    font-size: 50px;
    font-weight: 500;
    color: #ea7b08;
}

.main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 20vh;
}

.inputs {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button {
    width: 100%;
}

.button button {
    background-color: #298487;
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 18px;
    color: #f9f9f9;
    font-size: 20px;
    cursor: pointer;
}

.sign-up button {
    background-color: transparent;
    border: none;
    color: #ea7b08;
    font-size: 16px;
    margin-block: 2rem;
    cursor: pointer;
}

.error-container {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
}

.backend-error-msg {
    font-size: 20px;
    color: #ea7b08;
}

@media screen and (max-width:800px) {
    .body {
        background-image: none;
        align-items: start;
        max-height: 100dvh;
        max-width: 100dvw;
    }

    .container {
        width: 100%;
        min-height: 100dvh;
        border-radius: 0;
    }
}

@media screen and (max-width:560px) {
    .body {
        background-image: none;
        align-items: center;
        max-height: 100dvh;
        max-width: 100dvw;
    }

    .container {
        width: 100%;
        height: 100dvh;
        border-radius: 0;
    }
}

@media screen and (min-width: 801px) and (max-height: 724px) {
    .body {
        height: auto;
        align-items: center;
        justify-content: center;
        min-height: 100dvh;
    }

    .container {
        height: auto;
        gap: 5em;
    }
}
</style>