<template>
    <div class="wait-list-page">
        <WaitListInput/>
    </div>
</template>

<script >
    import WaitListInput from '@/components/WaitListInput.vue';
    export default{
        components: {
            WaitListInput,
        },
    }
</script>

<style>
    .wait-list-page{
        min-height: 100vh;
        height: auto;
        width: 100vw;
        background-color: #FB8409;
        background-image: url('../assets/half-white-blue-lines-bg.svg');
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2em 0;
        background-attachment: fixed;
    }
</style>