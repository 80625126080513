<template>
  <div class="loading-container">
    <img id="cebola-icon" src="../assets/icons/cebola_gradient.svg" alt="Ícone de carregamento">
  </div>
</template>

<script></script>

<style scoped>
.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
}

#cebola-icon {
  width: 150px;
  height: 150px;
  filter: brightness(3);
  transform-style: preserve-3d;
  animation: cebolaCombined 2s ease infinite;
}

@keyframes cebolaCombined {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(0deg) scale(1.1);
  }

  100% {
    transform: rotate(1080deg) scale(1);
  }
}
</style>