<template>
    <div class="container">
      <h2>{{ name }}</h2>
      <div class="insignia-container">
        <img
          v-for="(img, index) in imagensCompletas"
          :key="index"
          :src="img"
          class="insignia"
          alt="Imagem"
        />
      </div>
    </div>
  </template>
  
  <script>
  import DefaultImages from "@/assets/icons/default-insignia.svg"
  export default {
    name: 'GaleriaImagens',
    props: {
      name: {
        type: String,
        required: true
      },
      caminhos: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      imagensCompletas() {
        const caminhoPadrao = DefaultImages;
        const imagens = Array(4).fill(caminhoPadrao);
  
        this.caminhos.forEach((caminho, index) => {
          if (index < 4 && caminho) {
            imagens[index] = caminho;
          }
        });
  
        return imagens;
      }
    }
  };
  </script>

  <style scoped>
    .insignia{
        height: 60px;
    }
    .insignia-container{
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    h2{
      font-family: 'League Spartan', sans-serif;
      font-weight: 500;
      text-align: center;
    }
    .container{
      height: 100%;
      width: 100%;
      display: flex;
      gap: 10px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    @media (min-width: 820px){
      .container{
        justify-content: start;
        align-items: start;
        width: 60%;
        gap:40px ;
      }
      h2{
        text-align: start;
        font-size: 50px;
      }
      .insignia{
        height: 70px;
      }
    }
  </style>
  