<template>
    <div class="successful-body">
        <LoadingComponent v-show="isLoading" />
        <ContainerImageSuccess :name="name" />
    </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');
.successful-body {
    min-height: 100vh;
    height: auto;
    width: 100vw;
    background-color: #FB8409;
    background-image: url('../assets/half-white-blue-lines-bg.svg');
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em 0;
    background-attachment: fixed;
}
</style>

<script>
    import ContainerImageSuccess from '@/components/ContainerImageSuccess.vue';
    export default {
        components: {
            ContainerImageSuccess,
        },
    };
</script>