<template>
    <LoadingComponent v-if="loading" class="loading" />
    <div class="quiz-container">
        <div v-for="question in paginatedQuestions" :key="question.id" class="question-section">
            <div class="desc-card">
                <p>{{ question.statement }}</p>
            </div>

            <div class="alternatives-container">
                <div class="alternatives-group">
                    <button v-for="alternative in question.alternatives"
                        @click="selectAlternative(question.id, alternative.value)" :key="alternative.id"
                        class="alternative">
                        <div class="img-alternative">
                            <img :src="iconImage(alternative.statement)"
                                :class="{ 'selected': isSelected(question.id, alternative.value) }">
                        </div>
                        <div class="desc-alternative">
                            <p>{{ alternative.statement }}</p>
                        </div>
                    </button>
                </div>
            </div>

            <div class="divider"></div>
        </div>

        <div v-show="isLastPage && !loading" class="affinity-statements">
            <div class="desc-card">
                <p v-if="greaterAffinityStatements.length !== 0">Escolha até 3 afirmações que você mais se identificou.
                </p>
                <p v-else>Questionário finalizado!</p>
            </div>

            <div v-show="greaterAffinityStatements.length !== 0" v-for="statement in greaterAffinityStatements"
                class="statement" :key="statement.id">
                <p :class="{ 'actived': selectedStatements.includes(statement.id) }" @click="toggleColor(statement.id)">
                    {{ statement.statement }}
                </p>
                <div class="divider"></div>
            </div>
        </div>

        <div class="actions" v-if="!loading">
            <button @click="previousPage" :disabled="currentPage === 1" :style="currentPage == 1 ? 'opacity: 0' : ''"
                id="prev-page" class="arrow-page">
                <img src="../assets/icons/left-arrow.png" />
            </button>

            <button @click="nextPage" :disabled="currentPage === totalPages || !allAnswered" id="next-page"
                class="arrow-page" v-if="!isLastPage">
                <img src="../assets/icons/right-arrow.png" />
            </button>
            <div class="Genericbutton" v-else>
                <GenericButton @click="nextPage" text="Finalizar" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import router from '@/router';
import LoadingComponent from '@/components/LoadingComponent.vue';
import GenericButton from '@/components/GenericButton.vue'


export default {
  data() {
    return {
        token: '',
        idQuiz: '',
        idUser: '',
        questions: [],
        responses: {},
        selectedStatements: [],
        responsesPerPage: 0,
        currentPage: 1,
        questionsPerPage: 3,
        loading: true,
        allAnswered: false,
        responsesChanged: false,

        themes: {
            Especialista: 0,
            Independente: 0,
            Audacioso: 0,
            Empreendedor: 0,
            Administrador: 0,
            Engajado: 0,
            Prudente: 0,
            Equilibrado: 0
        },
    };
  },

  components: {
    LoadingComponent,
    GenericButton,
  },

  computed: {
    paginatedQuestions() {
      const start = (this.currentPage - 1) * this.questionsPerPage;
      const end = start + this.questionsPerPage;
      return this.questions.slice(start, end);
    },

    totalPages() {
        return Math.ceil(this.questions.length / this.questionsPerPage) + 1;
    },

    isLastPage() {
        return this.currentPage === this.totalPages
    },

    greaterAffinityStatements() {
        const responses = Object.keys(this.responses)
        .filter(id => this.responses[id] > 3)
        .map(id => ({ id: id, value: this.responses[id]}))

        return responses.map(item => {
            const statementObj = this.questions.find(statement => statement.id === item.id);

            return {
                id: item.id,
                value: item.value,
                statement: statementObj.statement,
                theme: statementObj.theme
            };
        });
    },

    motivators() {
        return Object.keys(this.themes)
        .map(theme => ({theme: theme, score: this.themes[theme]}))
    }
  },

  methods: {
    async fetchQuiz() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/quiz/${this.idUser}/${this.idQuiz}`, {
            headers: {
                'Authorization': this.token,
            }
        });
        this.questions = response.data.questions;
        await this.loadStoredResponses();
        this.loading = false;
      } catch (error) {
        if(error.response.data?.status === 400) {
            router.push(`/start-quiz/${this.idUser}/${this.idQuiz}`)
        }
        else if(error.response.data?.status  === 403) {
            router.push(`/start-quiz/${error.response.data?.expectedIdUser}/${this.idQuiz}`)
        }
        else if (error.response.data?.status === 401) {
            router.push({path: "/", query: {"expired": true}});
        }
        else {
            router.push("/error/system-failure")
        }
      }
    },

    async loadStoredResponses() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/responses/${this.idUser}/${this.idQuiz}`, {
            headers: {
                'Authorization': this.token,
            }
        });
        const responses = response.data.responses || [];
        responses.forEach(resp => this.responses[resp.questionId] = resp.value) || {}
        this.applyStoredResponsesToCurrentPage();
      } catch (error) {
        if(error.data.stattus === 400) {
            router.push(`/start-quiz/${this.idUser}/${this.idQuiz}`)
        }
        else if(error.response.data?.status  === 403) {
            router.push(`/start-quiz/${error.response.data?.expectedIdUser}/${this.idQuiz}`)
        }
        else if (error.response.data?.status === 401) {
            router.push({path: "/", query: {"expired": true}});
        }
        else {
            router.push("/error/system-failure")
        }
      }
    },

    applyStoredResponsesToCurrentPage() {
      this.paginatedQuestions.forEach((question) => {
        if (this.responses[question.id]) {
          question.selectedAlternative = this.responses[question.id];
        }
        this.allAnswered = this.allQuestionsAnswered()
      });
    },

    selectAlternative(questionId, alternativeValue) {
        const question = this.questions.find(q => q.id === questionId);
        const previousValue = this.responses[questionId];

        if (previousValue !== undefined) {
        this.incrementTheme(question.theme, -previousValue);
        }

        this.responses[questionId] = alternativeValue;

        this.incrementTheme(question.theme, alternativeValue);

        this.allAnswered = this.allQuestionsAnswered()
        this.responsesChanged = true;

        this.$forceUpdate();
    },

    isSelected(questionId, alternativeId) {
      return this.responses[questionId] === alternativeId;
    },

    allQuestionsAnswered() {
        return this.paginatedQuestions.every(question => this.responses[question.id] !== undefined);
    },

    incrementTheme(theme, value) {
        this.themes[theme] += value
    },  

    async saveResponses() {
      const questionsToSave = this.paginatedQuestions.map((question) => ({
        idQuestion: question.id,
        value: this.responses[question.id],
      }));

      //this.paginatedQuestions.map((question) => this.incrementTheme(question.theme));

      try {
        await axios.post(`${process.env.VUE_APP_BASE_URL}/quiz/${this.idUser}/${this.idQuiz}`, {
          responses: questionsToSave,
        }, {
            headers: {
                'Authorization': this.token,
            }
        });
      } catch (error) {
        if(error.data.stattus === 400) {
            router.push(`/start-quiz/${this.idUser}/${this.idQuiz}`)
        }
        else if(error.response.data?.status  === 403) {
            router.push(`/start-quiz/${error.response.data?.expectedIdUser}/${this.idQuiz}`)
        }
        else if (error.response.data?.status === 401) {
            router.push({path: "/", query: {"expired": true}});
        }
        else {
            router.push("/error/system-failure")
        }
      }
    },
    
    async saveMotivators() {
        this.loading = true
        try {
            axios.post(`${process.env.VUE_APP_BASE_URL}/motivators/${this.idUser}`, 
                {
                    motivators: this.motivators
                },
                {
                    headers: {
                        'Authorization': this.token,
                    }
                }
            )
            router.push(`/final`);
        } catch (error) {
            if(error.response.data?.status  === 403) {
                router.push(`/start-quiz/${error.response.data?.expectedIdUser}/${this.idQuiz}`)
            }
            else if (error.response.data?.status === 401) {
                router.push({path: "/", query: {"expired": true}});
            }
            else {
                router.push(`/error/system-failure`);
            }
        } finally {
            this.loading = false
        }
    },

    async nextPage() {
        if (this.allQuestionsAnswered()) {
            if (!this.isLastPage) {
                window.scrollTo({ top: 0});
                if (this.responsesChanged) {
                    this.loading = true;

                    await this.saveResponses();
                    
                    this.responsesChanged = false;
                    
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                }
                this.allAnswered = false;
                this.currentPage++;
                this.applyStoredResponsesToCurrentPage();

                this.greaterAffinityStatements.forEach(obj => this.incrementTheme(obj.theme, 1))
            }
            else {
                this.saveMotivators()
            }
      } else {
        alert('Por favor, responda todas as perguntas antes de avançar.');
      }
    },

    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.applyStoredResponsesToCurrentPage();
      }
    },

    iconImage(statement) {
        let image = '';
        switch (statement) {
            case 'Discordo Totalmente':
                image = require('@/assets/icons/discordo-totalmente.png');
                break;
            case 'Discordo':
                image = require('@/assets/icons/discordo.png');
                break;
            case 'Discordo Parcialmente':
                image = require('@/assets/icons/discordo-parcialmente.png');
                break;
            case 'Concordo Parcialmente':
                image = require('@/assets/icons/concordo-parcialmente.png');
                break;
            case 'Concordo':
                image = require('@/assets/icons/concordo.png');
                break;
            case 'Concordo Totalmente':
                image = require('@/assets/icons/concordo-totalmente.png');
                break;
            default:
                image = require('@/assets/icons/discordo-totalmente.png');
        }

        return image;
    },

    toggleColor(statementId) {
      if (this.selectedStatements.includes(statementId)) {
        this.selectedStatements = this.selectedStatements.filter(id => id !== statementId);
      } else if (this.selectedStatements.length < 3) {
        this.selectedStatements.push(statementId);
      }
    },

    isMobile() {
      if (window.innerWidth <= 768) {
        this.questionsPerPage = 1;
      }
    }
  },

  async mounted() {
    this.isMobile();
    window.addEventListener('resize', this.checkIfMobile);
    this.token = localStorage.getItem('userJWT');

    this.idUser = this.$route.params.idUser;
    this.idQuiz = this.$route.params.idQuiz;
    await this.fetchQuiz();
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

.quiz-container {
    font-family: 'League Spartan';
    color: #ea7b08;
    width: 100vw;
    min-height: 100vh;
    min-width: 341px;
    padding-top: 5rem;
    padding-inline: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ea7b08;
}

.question-section {
    width: 100%;
    min-height: 60dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 4rem;
}

.desc-card {
    width: 100%;
    min-height: 5rem;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    border-radius: 25px;
    box-shadow: 4px 6px 5px 2px #00000040;
}

.desc-card p {
    font-size: 28px;
    text-align: center;
}

.alternatives-container {
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.alternatives-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
}

.alternative {
    width: 6rem;
    height: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    flex: 0 1 calc(16.66% - 10px);
    border: none;
    color: #ffffff;
}

.selected {
    transform: translateY(-15px);
    box-shadow: 0px 0px 15px 5px #ffffff;
    filter: none;
    border-radius: 100%;
    transition: all .2s;
}

.img-alternative {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-alternative img {
    min-width: 100%;
    height: 100%;
    object-fit: contain;
    filter: drop-shadow(3px 5px 5px #00000040);
    transition: all .2s;
}

.desc-alternative {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 2px 2px 4px #00000040;
}

.desc-alternative p {
    text-align: center;
    font-weight: 600;
}

.affinity-statements {
    display: flex;
    flex-direction: column;
    gap: 4em;
    align-items: center;
    width: 100%;
    padding-bottom: 5rem;
}

.statement {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
}

.statement p {
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    color: #ffffff;
    transition: all ease .2s;
}

.statement p:hover {
    transform: translateY(-3px);
    cursor: pointer;
}

.statement p.actived {
    color: #ffffff;
    text-shadow: #ffffff 0 0 10px;
}

.divider {
    width: 95%;
    height: 3px;
    border-radius: 10px;
    background-color: #ffffff
}

.actions {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrow-page {
    height: 3rem;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
}

.Genericbutton {
    width: 22%;
}

button {
    cursor: pointer;
    transition: all .2s;
}

button:hover {
    transform: scale(1.1);
    transition: all .2s;
}

button:disabled {
    cursor: default;
    transform: none;
    opacity: .6;
}

@media (max-width: 700px) {
    .quiz-container {
        padding-block: 5rem;
        justify-content: space-between;
    }

    .question-section {
        flex: 1;
    }

    .alternatives-container {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .alternative {
        flex: 0 1 calc(33.33% - 10px);
    }

    .quiz-container {
        padding-block: 2rem;
        padding-inline: 1.5rem;
    }
}

@media (max-height: 510px) {
    .quiz-container {
        padding-top: 0;
    }

    .question-section {
        padding-bottom: 0;
    }

    .alternatives-container {
        padding-block: 1rem;
    }
}

@media (min-height: 700px) {
    .question-section {
        min-height: 25rem;
    }
}

@media (max-width: 700px) and (max-height: 650px) {
    .alternatives-container {
        padding-block: 2rem;
    }

    .img-alternative {
        width: 5rem;
    }

    .desc-alternative {
        font-size: 12px;
    }
}

@media (max-width: 375px) and (max-height: 670px) {
    .question-section {
        padding-bottom: 0;
    }
}
</style>