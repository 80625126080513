<template>
    <div class="container">
        <img src="../assets/error-system.svg" alt="Erro no Sistema">
    </div>
</template>

<script>
export default {
    mounted() {
        document.body.style.background = '#ea7b08';
    }
}
</script>

<style scoped>
.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
}
</style>